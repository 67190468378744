// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-about-layout-tsx": () => import("./../src/layouts/about-layout.tsx" /* webpackChunkName: "component---src-layouts-about-layout-tsx" */),
  "component---src-pages-about-index-cs-mdx": () => import("./../src/pages/about/index.cs.mdx" /* webpackChunkName: "component---src-pages-about-index-cs-mdx" */),
  "component---src-pages-about-index-en-mdx": () => import("./../src/pages/about/index.en.mdx" /* webpackChunkName: "component---src-pages-about-index-en-mdx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-index-mdx": () => import("./../src/pages/resume/index.mdx" /* webpackChunkName: "component---src-pages-resume-index-mdx" */),
  "component---src-pages-test-index-cs-mdx": () => import("./../src/pages/test/index.cs.mdx" /* webpackChunkName: "component---src-pages-test-index-cs-mdx" */),
  "component---src-pages-test-index-en-mdx": () => import("./../src/pages/test/index.en.mdx" /* webpackChunkName: "component---src-pages-test-index-en-mdx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nikola Rusakov Digital garden","short_name":"NR","start_url":"/","description":"Nikola Rusakov Digital garden and knowledge sharing platform.","display":"standalone","cache_busting_mode":"name","theme_color_in_head":false,"icon":"static/svg/nr.svg","icon_options":{"purpose":"maskable"},"lang":"en","localize":[{"start_url":"/cs/","lang":"cs","name":"Nikola Rusakov Digitální záhrada","short_name":"NR","description":"Nikola Rusakov digitální zahrada a platforma na sdílení vědomostí v jednom."}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"maximumFileSizeToCacheInBytes":5000000,"globPatterns":["**/*"]},"precachePages":["/en/*","/cs/*","/resume"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/theme/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"about":"/vercel/3cba753e/src/layouts/about-layout.tsx","resume":"/vercel/3cba753e/src/layouts/resume-layout.tsx"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
